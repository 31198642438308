html, body
{
  color: #333333;
  padding: 20px;
}

h1, h2, h3
{
  text-align:center;
}

p, ul {
  text-align: left;
}